<template>
	<div class="m-login">
		<div class="m-login-form">
			<div class="m-login-left-top"></div>
			<div class="m-login-box">
				<p class="m-login-font1 u-m-b-30">在线学习系统</p>
				<el-form ref="form" :rules="rules" :model="form" label-width="0">
					<el-form-item prop="username">
						<el-input v-model="form.username" placeholder="姓名"></el-input>
					</el-form-item>
					<el-form-item prop="phone">
						<el-input v-model="form.phone" placeholder="手机号"></el-input>
					</el-form-item>
					<el-form-item prop="code">
						<el-row :gutter="10">
							<el-col :span="16">
								<el-input v-model="form.code" placeholder="短信验证码"></el-input>
							</el-col>
							<el-col :span="8">
								<el-button type="primary" class="m-btn" v-if="codeTime == '获取验证码' || codeTime == '再次获取验证码'" @click="sendCode">获取验证码</el-button>
								<el-button type="primary" class="m-btn" v-else >{{codeTime}}s</el-button>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item prop="education">
						<el-row :gutter="10">
							<el-col>
								<el-input v-model="form.education" placeholder="学历"></el-input>
							</el-col>

						</el-row>
					</el-form-item>

          <el-form-item prop="education">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-select  v-model="form.user_cate"  placeholder="请选择业务标识">
                  <el-option v-for="(item,index) in usercate" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select v-model="form.specialty" placeholder="请选择专业">
                  <el-option v-for="(item,index) in majorList" :key="index" :label="item.title" :value="item.value">
                  </el-option>

                </el-select>
              </el-col>
            </el-row>
          </el-form-item>

					<el-form-item prop="password">
						<el-input type="password" v-model="form.password" placeholder="密码"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button style="background: #437EFE;border: 0;width: 100%;height: 48px;" type="primary" @click="onSubmit">注 册</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="m-login-right-bottom"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import {register,sendSms} from "@/api/common.js"
	import {to_link} from "@/router/api.js"
  import {getUserCate} from "@/api/usercate.js"
	export default {
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!(/^1[3456789]\d{9}$/.test(value))) {
					callback(new Error('手机号不正确'));
				} else {
					callback()
				}
			}
			return {
				rules: {
					username: [{
						required: true,
						message: '请输入姓名',
						trigger: ['blur', 'change']
					}],
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: ['blur', 'change']
						},
						{
							validator: checkPhone,
							trigger: ['blur', 'change']
						}
					],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: ['blur', 'change']
					}],
					education: [{
						required: true,
						message: '请输入学历',
						trigger: ['blur', 'change']
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: ['blur', 'change']
					}],
				},
				form: {
					username: '',
					phone: '',
					education: '',
					specialty: '1',
					password: '',
					code: '',
          user_cate:''
				},
				codeTime:'获取验证码',
        usercate:''
			}
		},
		computed: {
			...mapState([
				'majorList'
			])
		},
    mounted() {

      this.getInfo()
    },
		methods: {
      async getInfo(){
        let res = await getUserCate({})
        if(res.code == 200){
          this.usercate=res.data
          console.log(this.usercate,'this.usercate');
        }else{
          this.$message.error(res.message)
          this.$router.back()
        }
      },
			//发送验证码
			async sendCode(){
				if(!(/^1[3456789]\d{9}$/.test(this.form.phone))){
					this.$message.error('手机号错误')
					return false;
				}
				let res = await sendSms({phone:this.form.phone})
				if(res.code == 200){
					this.$message.success(res.message)
					this.codeTime = 60
					let _time = setInterval(()=>{
						if(this.codeTime <= 0){
							this.codeTime = '再次获取验证码'
							clearInterval(_time)
							return false
						}
						this.codeTime -= 1
					},1000)
				}else{
					this.$message.error(res.message)
				}
			},
			onSubmit() {
				this.$refs['form'].validate(async (valid) => {
					if(valid){
            if (this.form.user_cate=='') {
              this.$message.error('请选择业务标识')
              return false
            }
						let res = await register(this.form)
						if(res.code == 200){
							this.$message.success(res.message)
							to_link('/login')
						}else{
							this.$message.error(res.message)
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-login {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: url(../assets/img/common/login-bg.png) no-repeat;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;

		.m-login-form {
			display: flex;
			position: relative;
			width: 486px;
			height: 564px;
			background: rgba($color: #fff, $alpha: 0.65);
			border-radius: 20px;

			.m-login-font1 {
				text-align: center;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #000000;
				line-height: 24px;
				margin-top: 40px;
			}

			.m-login-font2 {
				font-size: 16px;
				color: #000;
				cursor: pointer;
			}

			.m-login-left-top {
				position: absolute;
				left: 0;
				top: 0;
				width: 97px;
				height: 92px;
				background: url(../assets/img/common/login-icon2.png);
				background-size: 100% 100%;
			}

			.m-login-right-bottom {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 97px;
				height: 92px;
				background: url(../assets/img/common/login-icon1.png);
				background-size: 100% 100%;
			}

			.m-login-box {
				display: flex;
				flex-direction: column;
				width: 320px;
				margin: 0px auto;

				.m-btn {
					padding: 0;
					width: 100%;
					height: 40px;
					background: #fff;
					border: 0;
					font-size: 14px;
					color: #676767;
				}

				.m-img-icon {
					display: flex;
					align-items: center;
					height: 100%;

					img {
						width: 18px;
						height: 18px;
					}
				}
			}
		}
	}
</style>
