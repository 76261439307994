import request from '@/utils/request.js'

// 专家分类
export function getTeamCate(query={}) {
  return request({
    url: 'api/getTeamCate',
    method: 'POST',
    params: query
  })
}

// 用户分类列表
export function getUserCate(query={}) {
  return request({
    url: 'api/getUserCate',
    method: 'POST',
    params: query
  })
}

// 专家详情
export function getTeacherDetail(query={}) {
  return request({
    url: 'api/getTeacherDetail',
    method: 'POST',
    params: query
  })
}
